import React from 'react'
import MainLoader from '../loader/MainLoader'
import { ImageLoader } from '../basic/ImageLoader'

const SectionHeroBannerFixed = ( { settings, contentCollection } ) => {
    return (
        <section className={`
            ratio
            hero-banner-fixed-${settings && settings.heroSize ? settings.heroSize : 'md'}
            position-relative
            ${settings && settings.backgroundColor ? `bg-` + settings.backgroundColor : ''}
            `}>
                <ImageLoader settings={{"fit": "cover", "srcType": "reference", "src": settings.backgroundImage}}/>
                <div
                    className="container-fluid position-absolute d-flex bg-overlay"
                    style={settings && settings.backgroundImage ? {
                        '--overlayopacity': (settings.backgroundOverlay ? settings.backgroundOverlay : '1'),
                        '--overlaycolor': `var(--bs-${settings.backgroundColor ? settings.backgroundColor : ''})`
                        } :{}}>
                    <div className="row flex-fill align-items-center">
                        <div className="col-12 text-white text-center">

                            {/* HERE STARTS CONTENT*/}

                            <div className="row justify-content-center">
                                <div className="col-5">
                                    <h1>wood-projects</h1>
                                </div>
                            </div>

                            {/* HERE STARTS CONTENT*/}

                        </div>
                    </div>
                </div>
        </section>
  )
}

export default SectionHeroBannerFixed