import React from 'react'
import MainLoader from '../loader/MainLoader'
import { ImageLoader } from '../basic/ImageLoader'
import files from '../../data/files.json'

const SectionHeroBannerDynamic = ( { settings, contentCollection, collectionSettings } ) => {
    
    const srcSet = settings.srcType === 'reference' && files[settings.backgroundImage] ? files[settings.backgroundImage] : {};

    return (
        <section className={`${settings.backgroundColor ? `bg-${settings.backgroundColor}` : ''} bg-overlay`}
            style={settings.backgroundImage ? {
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `image-set(url(${srcSet.sizes[(srcSet.sizes.length - 2)].url}) 1x, url(${srcSet.sizes[(srcSet.sizes.length - 1)].url}) 2x, url(${srcSet.src.url}) 3x)`,
                '--overlayopacity': settings.backgroundOverlay,
                '--overlaycolor': `var(--bs-${settings.backgroundColor})`
            } : {}}
            >
            <div className={`container min-size-${settings.heroSize ? settings.heroSize : 'md'} d-flex align-items-center`}>
                <div className="row flex-fill align-items-center">
                    <div className="col-12 text-white text-center">
                        {contentCollection && <MainLoader contentCollection={contentCollection} collectionSettings={collectionSettings}/>}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionHeroBannerDynamic