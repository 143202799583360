import React from 'react'

export const BasicAlert = ( { color, message }) => {
    return (
        <>
            {message && 
                <div class={`alert alert-${color ? color : 'primary'}`} role="alert">
                    {message ? message : 'geen bericht'}
                </div>
            }
        </>
    )
}
