import React from 'react'
import files from '../../data/files.json'

export const ImageLoader = ( { settings } ) => {
    let srcSet = {}
    let imageSizes = []
    if (settings.srcType === 'reference' && files[settings.src]) {
        srcSet = files[settings.src]
        imageSizes = srcSet.sizes.map(size => size.width);
    }
    return (<>
        {settings.srcType === 'reference' ?
            <img 
                id={srcSet.id} 
                className={`${settings && settings.fit ? `object-fit-${settings.fit}` : '' }`}
                srcSet={srcSet.sizes.map(size => `${size.url} ${size.width}w`).join(',\n')}/>
            :
                <img 
                src={settings.src ? settings.src : 'https://picsum.photos/600/400'}
                className={`${settings && settings.fit ? `object-fit-${settings.fit}` : '' }`}
                alt={settings && settings.alt ? settings.alt : ''}
                />
        }
    </>)
}
