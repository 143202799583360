import React from 'react'
import { createRoot, hydrateRoot } from "react-dom/client";
import './custom.scss'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import App from './App';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  const root = hydrateRoot(rootElement);
  root.render(<App />);
} else {
  const root = createRoot(rootElement)
  root.render(<App />);
}



