import React from 'react'
import { Helmet } from 'react-helmet-async'
import settings from '../data/settings.json'

// GLOBAL COMPONENTS
import NavBar from '../compontents/global/NavBar'
import Footer from '../compontents/global/Footer'


// PAGES
import pages from '../data/pages.json'
import MainLoader from '../compontents/loader/MainLoader'
import NavBarWP from '../compontents/global/NavBarWP'

const GeneralView = ( { page, meta }) => {
    
    return (
      <>
        <Helmet>
           <title>{meta && meta.title ? meta.title : settings.meta.title}</title>
           <meta name="description" content={meta && meta.description ? meta.description : settings.meta.description}/>
           <meta name="robots" content={`${meta.index !== false ? 'index' : 'noindex'}, ${meta.follow !== false ? 'follow' : 'nofollow'} `}/>
           {pages && page && pages[page].slug ? <link rel="canonical" href={pages[page].slug}/> : ''}
        </Helmet>
        <NavBarWP currentPage={page}/>
            {pages && page && pages[page].contentCollection &&
                <MainLoader
                contentType={pages[page].contentType}
                contentCollection={pages[page].contentCollection}
                />
            }
          <Footer/>
      </>
    )
}

export default GeneralView