import React from 'react'
import collections from '../../data/collections.json'
import generalSettings from '../../data/settings.json'
import pages from '../../data/pages.json'
import { Link } from 'react-router-dom'
import { BSContentImage } from '../basic/BSContentImage'

const NavBarWP = ( { currentPage }) => {

    const navigation = collections[generalSettings.mainNavigation]

    return (
        <>
        {navigation &&
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top  position-absolute navbar-transparent">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <BSContentImage 
                            fluid={navigation.contentCollection[0].settings.fluid}
                            src={navigation.contentCollection[0].settings.src}
                            alt={navigation.contentCollection[0].settings.alt}
                            width='200'
                            /> 
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav ms-auto ">
                            <a className="nav-link disabled  text-white pe-3 fw-bold text-uppercase"><small>Bel voor een afspraak</small></a>
                            <a className='btn btn-sm btn-outline-white fw-bold' href='tel:+32474014272'>
                                +32 474 01 42 72
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        }</>
    )
}

export default NavBarWP