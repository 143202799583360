import React from 'react'

const SnippetButton = ( { settings, contentCollection } ) => {
    return (
        <a className={`
            btn
            btn-${settings.color}
            ${settings.textColor ? `text-${settings.textColor}` : ''}
            ${settings.classes ? settings.classes : ''}
            me-3
            mb-3
        `} href={settings.path} target={settings.targetBlank ? '_blank' : ''}>
            {settings.label}
        </a>
    )
}

export default SnippetButton