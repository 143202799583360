import React from 'react'

export const BSContentImage = ( { fluid, src, alt, width }) => {
    return (
        <>
            <img src={src} alt={alt} width={width}></img>
            
        </>
    )
}
