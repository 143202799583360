import React from 'react'

const Stylesheet = () => {
    return (
        <>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h1>h1. Bootstrap heading</h1>
                            <h2>h2. Bootstrap heading</h2>
                            <h3>h3. Bootstrap heading</h3>
                            <h4>h4. Bootstrap heading</h4>
                            <h5>h5. Bootstrap heading</h5>
                            <h6>h6. Bootstrap heading</h6>
                            <h1 className="display-1">Display 1</h1>
                            <h1 className="display-2">Display 2</h1>
                            <h1 className="display-3">Display 3</h1>
                            <h1 className="display-4">Display 4</h1>
                            <h1 className="display-5">Display 5</h1>
                            <h1 className="display-6">Display 6</h1>
                            <p className="lead">
                                This is a lead paragraph. It stands out from regular paragraphs.
                            </p>
                            <p>You can use the mark tag to <mark>highlight</mark> text.</p>
                            <p><del>This line of text is meant to be treated as deleted text.</del></p>
                            <p><s>This line of text is meant to be treated as no longer accurate.</s></p>
                            <p><ins>This line of text is meant to be treated as an addition to the document.</ins></p>
                            <p><u>This line of text will render as underlined.</u></p>
                            <p><small>This line of text is meant to be treated as fine print.</small></p>
                            <p><strong>This line rendered as bold text.</strong></p>
                            <p><em>This line rendered as italicized text.</em></p>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col d-flex gap-3">
                            <button type="button" className="btn btn-primary">Primary</button>
                            <button type="button" className="btn btn-secondary">Secondary</button>
                            <button type="button" className="btn btn-success">Success</button>
                            <button type="button" className="btn btn-danger">Danger</button>
                            <button type="button" className="btn btn-warning">Warning</button>
                            <button type="button" className="btn btn-info">Info</button>
                            <button type="button" className="btn btn-light">Light</button>
                            <button type="button" className="btn btn-dark">Dark</button>
                            <button type="button" className="btn btn-link">Link</button>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col d-flex gap-3">
                            <button type="button" className="btn btn-outline-primary">Primary</button>
                            <button type="button" className="btn btn-outline-secondary">Secondary</button>
                            <button type="button" className="btn btn-outline-success">Success</button>
                            <button type="button" className="btn btn-outline-danger">Danger</button>
                            <button type="button" className="btn btn-outline-warning">Warning</button>
                            <button type="button" className="btn btn-outline-info">Info</button>
                            <button type="button" className="btn btn-outline-light">Light</button>
                            <button type="button" className="btn btn-outline-dark">Dark</button>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col d-flex gap-3">
                            <button type="button" className="btn btn-lg btn-primary">Primary</button>
                            <button type="button" className="btn btn-lg btn-secondary">Secondary</button>
                            <button type="button" className="btn btn-lg btn-success">Success</button>
                            <button type="button" className="btn btn-lg btn-danger">Danger</button>
                            <button type="button" className="btn btn-lg btn-warning">Warning</button>
                            <button type="button" className="btn btn-lg btn-info">Info</button>
                            <button type="button" className="btn btn-lg btn-light">Light</button>
                            <button type="button" className="btn btn-lg btn-dark">Dark</button>
                            <button type="button" className="btn btn-lg btn-link">Link</button>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col d-flex gap-3">
                            <button type="button" className="btn btn-lg btn-outline-primary">Primary</button>
                            <button type="button" className="btn btn-lg btn-outline-secondary">Secondary</button>
                            <button type="button" className="btn btn-lg btn-outline-success">Success</button>
                            <button type="button" className="btn btn-lg btn-outline-danger">Danger</button>
                            <button type="button" className="btn btn-lg btn-outline-warning">Warning</button>
                            <button type="button" className="btn btn-lg btn-outline-info">Info</button>
                            <button type="button" className="btn btn-lg btn-outline-light">Light</button>
                            <button type="button" className="btn btn-lg btn-outline-dark">Dark</button>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default Stylesheet