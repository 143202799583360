import React from 'react'

const SnippetText = ( { settings } ) => {
    return (
        <>{settings && settings.typography && settings.text && React.createElement(settings.typography, { 
            className: `
                ${settings.classes ? settings.classes : ''}
                ${settings.textColor ? `text-${settings.textColor}` : ''}
                ${settings.textAlign ? `text-${settings.textAlign}` : ''}
                mb-3` 
        }, settings.text)}</> 
    )
}

export default SnippetText