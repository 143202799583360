import React from 'react'
import MainLoader from '../loader/MainLoader'

const BlockBasic = ( { settings, contentCollection, collectionSettings } ) => {
    
  return (
    <div className={settings && settings.classes ? settings.classes : ''}>
        <MainLoader contentCollection={contentCollection} collectionSettings={collectionSettings}/>
    </div>
  )
}

export default BlockBasic