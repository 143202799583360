import React from 'react'
import MainLoader from '../loader/MainLoader'

const BlockBasicCol = ( { settings, contentCollection, collectionSettings } ) => {

    

    return (
        <div className={`
        col${settings && settings.responsive ? `-lg`: ''}${settings && settings.colSize ? `-${settings.colSize}` : ''}
        ${settings && settings.textAlign && settings.textAlign}
        ${settings && settings.classes ? settings.classes : ''}
        ${settings && settings.backgroundColor ? `bg-${settings.backgroundColor}` : ''}
        `}>
            <div className={`
                ${settings && settings.padding ? `p-${settings.padding}`: ''}
                ${settings && settings.blockAlign ? `justify-content-${settings.blockAlign}` : ''} 
                ${settings && settings.gutter ? `vstack gap-${settings.gutter}` : ''} `}>
                {contentCollection && <MainLoader contentCollection={contentCollection} collectionSettings={collectionSettings}/>}
            </div>
        </div>
    )
}

export default BlockBasicCol