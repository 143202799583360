import axios from 'axios';
import settings from '../data/settings.json'

export const sendMail = async (props) => {
    try {
        const response = await axios.post('https://api.sendinblue.com/v3/smtp/email', {
            to: [
                {
                email: settings.integrations.sendinblue.to,
                name: settings.integrations.sendinblue.name
                }
            ],
            replyTo: {
                email: props.email,
                name: props.name
                },
            params: {
                toName: settings.integrations.sendinblue.name,
                name: props.name,
                email: props.email,
                phone: props.phone,
                message: props.message
            },
            templateId: 3, // The ID of the template to use
            // Other email data as required by the Sendinblue API
            }, {
            headers: {
                'Content-Type': 'application/json',
                'api-key': settings.integrations.sendinblue.key
            }
            });
        } catch (error) {
            console.log(error);
            // Handle errors
    }
}
