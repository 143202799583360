import React from 'react'
import { Link } from 'react-router-dom'
import settings from '../../data/settings.json'

const Footer = () => {
    return (
        <section className='bg-primary text-white'>
            <div className='container'>
                {settings.socials &&
                    <div className="row pb-5 justify-content-start">
                        {settings.socials.map((social) => (  
                            <div className="col-auto" key={social.platform} >
                                <Link to={social.url} target='_blank'>
                                    <i className={`${social.icon} text-white fs-3`}></i>
                                </Link>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </section>
    )
}

export default Footer