import React from 'react'
import RowBasicEmptyRow from '../rows/RowBasicEmptyRow';

const RowLoader = ( { item, collectionSettings } ) => {
    const rowMap = {
        RowBasicEmptyRow
    };
    let Component = rowMap[item.component] || null
    if(Component) {
        return (
            <Component 
                key={item.id}
                settings={item.settings}
                contentType={item.contentType}
                contentCollection={item.contentCollection}
                collectionSettings={collectionSettings}
            />
        )
    } else {
        console.log(`Compontent ${item.component} not found`)
    }
}

export default RowLoader