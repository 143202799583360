import React from 'react'
import SnippetText from '../snippets/SnippetText'
import SnippetButton from '../snippets/SnippetButton'
import SnippetImage from '../snippets/SnippetImage';
import SnippetHTML from '../snippets/SnippetHTML';
import SnippetPricingLine from '../snippets/SnippetPricingLine';
import SnippetMarkdown from '../snippets/SnippetMarkdown';

const SnippetLoader = ( { item, collectionSettings } ) => {
    const snippetMap = {
        SnippetText,
        SnippetButton,
        SnippetImage,
        SnippetHTML,
        SnippetPricingLine,
        SnippetMarkdown
    }
    let Component = snippetMap[item.component] || null
    if(Component) {
        return (
            <Component
                key={item.id}
                settings={item.settings}
                contentType={item.contentType}
                contentCollection={item.contentCollection}
                collectionSettings={collectionSettings}
            />
        )
    } else {
        console.log(`Compontent ${item.component} not found`)
    }
}

export default SnippetLoader