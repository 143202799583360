import React from 'react'
import { Link } from 'react-router-dom'
import { ImageLoader } from '../basic/ImageLoader'

const SnippetImage = ( { settings, contentCollection } ) => {

    /*
        "settings": {
            "classes": "",
            "ratio": "",
            "srcType: "",
            "src": "",
            "fit": "",
            "alt": "",
            "href": "",
            "width": "",
        }
    */

    return (
        <div className={settings && settings.classes ? settings.classes : '' }>
            <div className={`${settings && settings.ratio && settings.ratio !== 'none' ? `ratio ratio-${settings.ratio}`: ''}`}>
                {settings && settings.href ?
                <Link to={settings.href}>
                    <ImageLoader settings={settings}></ImageLoader>
                </Link>
                :
                <ImageLoader settings={settings}></ImageLoader>
                }
            </div>
        </div>
    )
}

export default SnippetImage