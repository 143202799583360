import React from 'react'
import MainLoader from '../loader/MainLoader'

const BlockBasicEmptyCol = ( { settings, contentCollection, collectionSettings } ) => {

    // Get collectionSettings
    settings = {...settings,
        gutter: settings?.gutter ?? collectionSettings?.gutter
    };

    return (
        <div className={`
            col${settings && settings.responsive && settings.colSize? `-lg`: ''}${settings && settings.colSize ? `-${settings.colSize}` : ''}
            ${settings && settings.gutter ? `p-${settings.gutter}` : ''}
            ${settings && settings.classes ? settings.classes : ''}
            `}>
            <div className={`
                ${settings && settings.padding ? `p-${settings.padding}`: ''}
                ${settings && settings.backgroundColor ? settings.backgroundColor : ''}
                ${settings && settings.stack ? settings.stack : '' } ${settings && settings.stack && settings.stackGap ? settings.stackGap : '' }
                `}>
                {contentCollection && <MainLoader contentCollection={contentCollection} collectionSettings={collectionSettings}/>}
            </div>
        </div>
    )
}

export default BlockBasicEmptyCol