import React from 'react'
import MainLoader from '../loader/MainLoader'

const SectionBasicEmptyContainer = ( { settings, contentCollection, collectionSettings } ) => {

    return (
        <section id={settings && settings.id ? settings.id : ''} className={`
            ${settings && settings.backgroundColor ? settings.backgroundColor : ''}
            ${settings && settings.textColor ? settings.textColor : ''}
            `}>
            <div className={`
                container${settings && settings.containerFluid ? '-fluid' : ''}
                ${settings && settings.classes ? settings.classes : ''}
                `}>

                    {contentCollection && <MainLoader contentCollection={contentCollection} collectionSettings={collectionSettings}/>}
            </div>
        </section>
    )
}

export default SectionBasicEmptyContainer