import React from 'react'

const SnippetPricingLine = ( { settings } ) => {
    return (
        <div className="row pb-3">
            {settings && settings.item && <div className="col text-start">{settings.item}</div>}
            {settings && settings.price && <div className="col-auto text-end fw-bold">€ {settings.price}</div>}
        </div>
    )
}

export default SnippetPricingLine