import React from 'react'
import MainLoader from '../loader/MainLoader'

const RowBasicEmptyRow = ( { settings , contentCollection, collectionSettings } ) => {

    // Provide collectionSettings
    collectionSettings = settings ? {
        gutter: settings.gutter,
    } : collectionSettings;
    
    return (
        <div className={`
            row
            ${settings && settings.padding ? settings.padding : ''}
            ${settings && settings.hAlign ? `justify-content-${settings.hAlign} ` : ''}
            ${settings && settings.vAlign ? `align-items-${settings.vAlign} ` : ''}

            ${settings && settings.classes ? settings.classes : ''}
            `}>
            {contentCollection && <MainLoader contentCollection={contentCollection} collectionSettings={collectionSettings}/>}
        </div>
    )
}

export default RowBasicEmptyRow